<template lang="pug">
  .notifications-filters.row
    .col-xl-2.col-lg-6.col-md-12.no-padding-inline
      .filter-item
        p.filter-title {{ $t("notifications.filters.ota") }}
        AppDropdown.ota-list(
          allow-empty
          checkbox
          batch-select
          multiple
          :value="filters.otas"
          :items="otaList"
          :placeholder="$t('notifications.filters.nothing_selected')"
          @select="setOtas"
          order-direction="keep"
        )
    .col-xl-2.col-lg-6.col-md-12.no-padding-inline
      .filter-item
        p.filter-title {{ $t("notifications.filters.object") }}
        AppDropdown.object(
          allow-empty
          checkbox
          multiple
          batch-select
          order-direction="keep"
          value-key="title"
          title-key="title"
          searchable
          :value="filters.objects"
          :items="objects"
          :placeholder="$t('notifications.filters.nothing_selected')"
          @select="setObjects"
        )
    .col-xl-2.col-lg-6.col-md-12.no-padding-inline
      .filter-item
        p.filter-title {{ $t(referenceItemNameTranslationKey) }}
        AppDropdown.reference-name(
          allow-empty
          checkbox
          batch-select
          multiple
          searchable
          :value="filters.reference_items"
          :items="referenceItems"
          :loading="referenceItemsLoading"
          :placeholder="$t('notifications.filters.nothing_selected')"
          @select="setReferenceItems"
        )
    .col-xl-2.col-lg-6.col-md-12.no-padding-inline
      .filter-item
        p.filter-title {{ $t("notifications.filters.car_class_name") }}
        AppDropdown.car-class-name(
          allow-empty
          checkbox
          batch-select
          multiple
          searchable
          :value="filters.car_classes"
          :items="carClasses"
          :loading="carClassesLoading"
          :placeholder="$t('notifications.filters.nothing_selected')"
          @select="setCarClasses"
        )
    .col-xl-2.col-lg-6.col-md-12.no-padding-inline
      .filter-item
        p.filter-title {{ $t("notifications.filters.status") }}
        AppDropdown.status(
          allow-empty
          checkbox
          multiple
          order-direction="keep"
          value-key="value"
          title-key="title"
          :value="filters.read_status"
          :items="statuses"
          :placeholder="$t('notifications.filters.nothing_selected')"
          @select="setStatus"
        )
    .col-xl-2.col-lg-6.col-md-12.no-padding-inline
      .filter-item
        p.filter-title {{ $t("notifications.filters.date") }}
        AppDatepicker.datepicker.w-100(
          :value="selectedDate"
          range
          @change="setDate"
        )
    .col-xl-12.filter-item.no-padding-right
      .actions
        AppIconButton.action-button(
          useAppIcon
          name="read"
          title="notifications.filters.read"
          icon="openedLetter"
          :disabled="!hasEditPermission() || disabled"
          @click="$emit('read-notifications')"
        )
        AppIconButton.action-button(
          useAppIcon
          name="unread"
          title="notifications.filters.unread"
          icon="unreadLetter"
          :disabled="!hasEditPermission() || disabled"
          @click="$emit('unread-notifications')"
        )
        AppIconButton.action-button(
          useAppIcon
          name="delete"
          title="notifications.filters.delete"
          icon="trash"
          :disabled="!hasEditPermission() || disabled"
          @click="$emit('delete-notifications')"
        )
</template>

<script>
  // mixins
  import withPermissions from "@/mixins/withPermissions"

  // misc
  import { isSharedInventoryEnabled } from "@/helpers/organization"

  export default {
    props: {
      otaList: {
        type: Array,
        default: () => new Array()
      },
      referenceItems: {
        type: Array,
        default: () => new Array()
      },
      statuses: {
        type: Array,
        default: () => new Array()
      },
      objects: {
        type: Array,
        default: () => new Array()
      },
      carClasses: {
        type: Array,
        default: () => new Array()
      },
      filters: {
        type: Object,
        required: true
      },
      disabled: {
        type: Boolean,
        default: true
      },
      referenceItemsLoading: {
        type: Boolean,
        default: false
      },
      carClassesLoading: {
        type: Boolean,
        default: false
      }
    },

    components: {
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppDatepicker: () => import("@/components/elements/AppDatepicker"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other")
    },

    mixins: [withPermissions],

    computed: {
      selectedDate({ filters: { start_date, end_date } }) {
        return [start_date, end_date]
      },

      referenceItemNameTranslationKey() {
        return isSharedInventoryEnabled()
          ? "notifications.filters.inventory_group_name"
          : "notifications.filters.shop_name"
      }
    },

    methods: {
      setOtas(otas) {
        this.applyFilters({ otas })
      },

      setReferenceItems(reference_items) {
        this.applyFilters({ reference_items })
      },

      setStatus(read_status) {
        this.applyFilters({ read_status })
      },

      setObjects(objects) {
        this.applyFilters({ objects })
      },

      setCarClasses(car_classes) {
        this.applyFilters({ car_classes })
      },

      setDate(dates) {
        const [start_date, end_date] = dates

        this.applyFilters({ start_date, end_date })
      },

      applyFilters(filters) {
        this.$emit("change-filters", { ...this.filters, ...filters })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  .notifications-filters
    height: auto
    margin: 0 -7px $topbar-margin-bottom -7px

    .filter-item
      +filter-title

      .datepicker
        padding-top: 7px
        padding-right: 15px
        margin-left: 8px

      .actions
        display: flex
        justify-content: flex-end
        padding-top: 9px
        padding-right: 7px

        .action-button:not(:last-child)
          margin-right: 7px

        .action-button
          padding: 5px 15px
          ::v-deep
            svg
              fill: $default-purple

      @media screen and (max-width: 650px)
        .actions
          flex-direction: column

          .action-button:not(:last-child)
            margin-bottom: 5px

          .action-button
            width: 100%
</style>
